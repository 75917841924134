export const USER_ROLE_TYPE_SYS_ADMIN = 1;
export const USER_ROLE_TYPE_ADMIN = 2;
export const USER_ROLE_TYPE_CREATOR = 3;
export const USER_ROLE_TYPE_VIEWER = 4;

export const USER_ROLE_NAME_SYS_ADMIN = "SysAdmin";
export const USER_ROLE_NAME_ADMIN = "Admin";
export const USER_ROLE_NAME_CREATOR = "Creator";
export const USER_ROLE_NAME_VIEWER = "Viewer";

export const userRoleNameMap = {
  [USER_ROLE_TYPE_SYS_ADMIN] : USER_ROLE_NAME_SYS_ADMIN,
  [USER_ROLE_TYPE_ADMIN] : USER_ROLE_NAME_ADMIN,
  [USER_ROLE_TYPE_CREATOR] : USER_ROLE_NAME_CREATOR,
  [USER_ROLE_TYPE_VIEWER] : USER_ROLE_NAME_VIEWER,
}

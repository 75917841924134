import {CLAIM_TYPE_ANALYTICS_READ, CLAIM_TYPE_USER_LIST} from "../constants/CLAIM_TYPES";
import {SCOPE_TYPE_COMPANY, SCOPE_TYPE_GLOBAL} from "../constants/SCOPE_TYPES";

export function containsClaim(permissions, fullClaimString, scope = null, target = null){
  try{
    if(scope != null) scope = Number.parseInt(scope);
  }catch(e){}
  try{
    if(target != null) target = Number.parseInt(target);
  }catch(e){}

  if(!Array.isArray(permissions)) return false;
  return permissions.find((perm)=>{
    if( ((scope === null) ||  (perm.scope === scope)) && ((target === null) || (perm.targetId === target)) ){
      return perm.claims.find((claim) => claim.name === fullClaimString)
    }
    return false;
  }) !== undefined;
}

/// example if a claim is  "Settings.Read"
// claimPrefix = "Settings" or "Analytics".
// return the postfix which is the second part of the string after the dot, examples include "Read", "List", "Update"
export function getClaimAccessLevels(permissions, claimPrefix, scope, target)
{
  return permissions.flatMap((perm)=>{
    if(perm.scope === scope && perm.targetId === target){
      return perm.claims.map((claim) => {
        const [searchClaimPrefix, claimAccessLevel] = claim.name.split();
        return (searchClaimPrefix === claimPrefix) ? claimAccessLevel : null
      })
    }
    return null;
  }).filter(accessLevel => accessLevel != null);
}

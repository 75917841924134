import React, { useEffect, useRef } from 'react'
import Head from 'next/head'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useRouter } from 'next/router'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from '../theme'
import MomentUtils from '@date-io/moment'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig, CustomNavigationClient } from '../authConfig'
import { DataProvider } from '../providers/data.js'
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { AppInsightsContextProvider, useAppInsights } from 'lib/useAppInsights'
import LayoutBase from 'components/layout/LayoutBase'
import { ApiProvider } from 'lib/api/ApiContext'
import 'scss/_fonts.scss'
import '@georgedrpg/pannellum-react-next/es/css/pannellum.css'
import '@georgedrpg/pannellum-react-next/es/css/style-textInfo.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import './main.css'
import { SWRConfig } from 'swr'
import { swrFetcher as fetcher } from '../lib/api/fetch-adapter'

export const msalInstance = new PublicClientApplication(msalConfig)

const useSnackbarStyles = makeStyles((theme) => ({
    contentRoot: {
        flexWrap: 'nowrap',
    },
}))

function MyApp({ Component, pageProps }) {
    const notistackRef = useRef(null)
    const router = useRouter()
    const navigationClient = new CustomNavigationClient(router)
    const snackbarClasses = useSnackbarStyles()
    msalInstance.setNavigationClient(navigationClient)

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles) jssStyles.parentElement.removeChild(jssStyles)

        msalInstance.handleRedirectPromise().then((res) => {
            if (!res) return
            if (localStorage.getItem('msal-next-url')) return localStorage.removeItem('msal-next-url')
            router.push('/company')
        })
    }, [])

    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key)
    }

    return (
        <>
            <Head>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
            </Head>
            <ThemeProvider theme={theme}>
                <AppInsightsContextProvider>
                    <AppInsightsWrapper>
                        <SnackbarProvider
                            ref={notistackRef}
                            classes={snackbarClasses}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            autoHideDuration={2000}
                            maxSnack={3}
                            action={(key) => (
                                <IconButton onClick={onClickDismiss(key)}>
                                    <CloseIcon />
                                </IconButton>
                            )}>
                            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                            <CssBaseline />
                            <MsalProvider instance={msalInstance}>
                                {/* TODO: Replace ApiProvider get requests with individual hooks like in src/hooks/api */}
                                <SWRConfig value={{ fetcher }}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <LayoutBase>
                                            <DataProvider>
                                                <ApiProvider>
                                                    <Component {...pageProps} />
                                                </ApiProvider>
                                            </DataProvider>
                                        </LayoutBase>
                                    </MuiPickersUtilsProvider>
                                </SWRConfig>
                            </MsalProvider>
                        </SnackbarProvider>
                    </AppInsightsWrapper>
                </AppInsightsContextProvider>
            </ThemeProvider>
        </>
    )
}

function AppInsightsWrapper({ children }) {
    const { reactPlugin, enableAppInsights } = useAppInsights()
    return !enableAppInsights ? (
        children
    ) : (
        <AppInsightsErrorBoundary appInsights={reactPlugin}>
            <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
    )
}

// Only uncomment this method if you have blocking itemData requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default MyApp

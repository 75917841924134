import { createContext, useContext  } from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

const AppInsightsContext = createContext(null)

export let appInsightsInstance = null // Allow functions which aren't hooks or components to access

export function useAppInsights() {
    const appInsights = useContext(AppInsightsContext)
    if (!appInsights) throw new Error('Please make sure AppInsightsContextProvider is available')
    return appInsights
}

export function AppInsightsContextProvider({ children }) {
    const value = useRawAppIsights()
    return <AppInsightsContext.Provider {...{ value, children }} />
}

export function useRawAppIsights() {
    const enableAppInsights = !!(typeof window !== 'undefined'
        && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')
        && process.env.NEXT_PUBLIC_MSAI_COMNNECTION_STRING
    )

    const reactPlugin = new ReactPlugin()
    const appInsights = enableAppInsights ? new ApplicationInsights({
        config: {
            connectionString: process.env.NEXT_PUBLIC_MSAI_COMNNECTION_STRING,
            disableFetchTracking: false,
            extensions: [reactPlugin]
        }
    }) : null

    appInsightsInstance  = appInsights

    if (enableAppInsights) appInsights.loadAppInsights()
    return { reactPlugin, appInsights, enableAppInsights }
}

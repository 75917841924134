const url = {
    app: {
        developments: '/developments',
        userManagement: '/user-management',
    },
    api: {
        BASE: process.env.NEXT_PUBLIC_API_URL_BASE,

        // Analytics
        ANALYTICS_STATS: '/analytics/stats',
        ANALYTICS_ITEMS: '/analytics/contentItems',
        ANALYTICS_EXPORT: '/analytics/export',

        // GET_USER: '/api/get-user',
        GET_USER: '/Users/currentUser',
        GET_ALL_USER_PREFRENCES: '/UserPreferences/all',
        GET_SINGLE_USER_PREFRENCES: '/UserPreferences/single',
        SAVE_USER_PREFRENCES: '/UserPreferences',
        USER_MANAGEMENT: '/users',
        USERS_COMPANY: '/users/c',
        USERS_DEVELOPMENTS: '/users/d',
        USER_DEVELOPMENTS: '/users/developments',
        USERS_PERMISSION: '/users/permission',
        USERS_PERMISSIONS: '/users/permissions',
        USERS_PERMISSIONS_LIST: '/users/permissions/list/c',
        ROLES: '/cms/roles',
        SEARCH_USERS: '/users/search',
        INVITE_USER: '/users/invite',
        REINVITE_USER: '/users/resendInvite',
        OTP_LOGIN: '/users/requestOTP',
        VERIFY_OTP: '/users/verifyOTP',
        OTP_LOGOUT: '/users/logout',
        IS_OTP_AUTHENTICATED: '/users/isAuthenticated',
        USERS_GET_LOGIN_TYPE: (email) => `/users/loginType/${email}`,
        REFRESH_TOKEN: '/users/refreshToken',
        
        // COMPANY
        COMPANY: '/Company',
        COMPANY_DOMAINS: '/Company/aliases',
        DEVELOPMENT_TYPES: '/Company/developmentTypes',

        // DEVELOPMENTS: '/api/developments',
        DEVELOPMENTS: '/Developments',
        DEVELOPMENT_MEMBERS: '/Developments/members',
        DEVELOPMENT_THUMB: '/Developments/thumb',

        // CREATE_DEVELOPMENT: '/api/development',
        DEVELOPMENT_SAVE: '/Developments',
        PRESENTATIONS: '/presentations',
        PRESENTATIONS_ID: '/presentations/presentationId',
        PRESENTATION_THUMB: '/presentations/thumbnail',
        ARHIVED: '/presentations/arhived',
        RESTORE: '/presentation/restore',
        PRESENTATION_MENU: '/Presentations/menu',

        //DATASETS
        DATASETS: '/Datasets',
        SCHEMAS: '/Datasets/schemas',

        // GET Files List
        ROOT_FILES: '/Files/list',
        ROOT_FOLDER_ID: '/Files/root',
        DEVELOPMENT_FILES: '/Files/list/d',
        FILES_FOLDER: '/Files/list/f',
        FILE_THUMBNAIL: '/Files/thumbnail/id',
        FILE_THUMBNAIL_BY_ASSET_ID: '/Files/thumbnail/guid',
        REQUEST_UPLOAD: '/Files/requestUpload',
        UPLOAD: '/Files/upload',
        DELETE_FILE: '/Files/delete/id',
        LOCK_FILE: '/Files/lock',
        DOWNLOAD_FILE: '/Files/download/id',
        DOWNLOAD_FILE_BY_ASSET_ID: '/Files/download/guid',
        MOVE_FILE: '/Files/transfer',
        CREATE_FOLDER: '/Files/folder',
        RENAME_FOLDER: '/Files/rename',
        RENAME_FILE: '/Files/rename',
        FILE_INFO_BY_ASSET_ID: '/Files/info/guid',
        FILE_INFO_BY_ID: '/Files/info/id',
        SEARCH_FILE: '/Files/search/c',

        COLUMN_TYPES: '/cms/datasets/columnTypes',
        CONTENT_TYPES: '/cms/content/types',
        CONTENT_TYPE_THUMBNAIL: '/cms/content/types/thumbnail/id',
        CREATE_IDD_CONTENT_ITEM: '/content/idd/create',
        CREATE_MEDIA_CONTENT_ITEM: '/ContentItems/create',
        IDD_CONTENT_ITEM: '/content/idd',
        MEDIA_CONTENT_ITEM: '/ContentItems',
        ICONS: '/Icons',
        ICONS_LIST: '/Icons/list',
        FONTS: '/Fonts',
        FONTS_LIST: '/Fonts/list',
        BRUSHES: '/Brushes',
        BRUSHES_LIST: '/Brushes/list',

        SETTINGS: '/Settings/schema',
        STYLE_SETTINGS: '/Settings/style',
        SAVE_SETTINGS: '/Settings',

        // Analytics
        REPORTING_SESSIONS_STARTED: '/reporting/GetNumberOfSessionsStarted',
        REPORTING_SESSIONS_DURATION: '/reporting/GetDurationOfSessions',
        REPORTING_SESSIONS_EMAIL_RATIO: '/reporting/GetSessionToEmailRatio',
        REPORTING_EMAIL_SENDOUTS: '/reporting/GetEmailSendouts',
        REPORTING_TOP_CONTENT_ITEMS: '/reporting/GetTopContentItems',
    }
}

export default url

import { createTheme } from '@material-ui/core/styles'

export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#0081ff',
            light: 'rgba(0,129,255,0.15)',
        },
        secondary: {
            main: '#d4eaff',
            contrastText: '#0081ff',
            // "&:hover": {
            //     backgroundColor: '#a1d1ff'
            // }
        },
        gray: {
            main: '#bfc7d0',
            contrastText: '#0081ff',
        },
        success: {
            main: '#09b66d',
            contrastText: '#ffffff',
        },
        error: {
            main: '#dc0047',
            mainHover: '#bc023c',
            contrastText: '#ffffff',
        },
        warning: {
            main: '#ff8a48',
            contrastText: '#ffffff',
        },
        info: {
            main: '#0081ff',
        },
    },
    typography: {
        button: {
            fontSize: 15,
            lineHeight: 1.8666666667,
            fontWeight: 400,
            textTransform: 'none',
        },
        fontSize: 15,
        h1: {
            fontSize: 24,
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: 1.2,
        },
        h2: {
            fontSize: 18,
            fontWeight: 400,
            letterSpacing: '0em',
        },
        h3: {
            fontSize: 15,
            lineHeight: 1.2,
        },
        body1: {
            fontSize: 15,
            lineHeight: 1.2,
            letterSpacing: '0em',
        },
        fontFamily: 'LandsecBrown-Regular',
    },
    overrides: {
        MuiFormLabel: {
            root: {
                lineHeight: 1.2,
                marginBottom: 12,
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: '#f8fafb',
                borderRadius: 5,
                color: '#44566c',

                '&:hover': {
                    backgroundColor: '#f8fafb',
                },

                '&.Mui-focused': {
                    backgroundColor: '#f8fafb',
                },
            },
            input: {
                padding: '11px 16px',

                '&::placeholder': {
                    color: '#8697a8',
                    opacity: 1,
                },
            },
            inputHiddenLabel: {
                paddingTop: 11,
                paddingBottom: 11,
            },
            underline: {
                '&:before': {
                    display: 'none',
                },
                '&:after': {
                    display: 'none',
                },
            },
            adornedStart: {
                paddingLeft: 16,
            },
            multiline: {
                padding: '11px 16px',
            },
        },
        MuiInputAdornment: {
            hiddenLabel: {
                marginTop: 0,
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1rem',
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 500,
            },
        },
        MuiDialogTitle: {
            root: {
                paddingTop: 24,
                paddingLeft: 40,
                paddingRight: 40,
            },
        },
        MuiDialogContent: {
            root: {
                paddingBottom: 40,
                paddingLeft: 40,
                paddingRight: 40,
            },
        },
        MuiMenu: {
            paper: {
                border: '1px solid #eaedf0',
                boxShadow: 'none',
            },
            list: {
                color: '#44566c',
                '& svg': {
                    marginRight: 16,
                },
                '&:focus': {
                    backgroundColor: '#f8fafb',
                },
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
        },
        MuiBreadcrumbs: {
            li: {
                fontSize: 13,
            },
        },
        MuiLinearProgress: {
            root: {
                height: 6,
                borderRadius: 3,
            },
            colorPrimary: {
                backgroundColor: '#eaedf0',
            },
            barColorPrimary: {
                backgroundColor: '#0081ff',
            },
        },
        MuiChip: {
            root: {
                borderRadius: 5,
            },
        },
    },
    props: {
        MuiButton: {
            variant: 'contained',
            color: 'primary',
            disableElevation: true,
        },
    },
}

// Create a theme instance.
const theme = createTheme(themeOptions)

export default theme

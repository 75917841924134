import { createContext, useState, useEffect } from 'react'

import useLocalStorage from '../lib/hooks/useLocalStorage'
import useCurrentUser from 'hooks/api/useCurrentUser'
import { containsClaim } from '../lib/claimsHelper'
import { SCOPE_TYPE_COMPANY, SCOPE_TYPE_DEVELOPMENT, SCOPE_TYPE_GLOBAL, SCOPE_TYPE_PRESENTATION } from '../constants/SCOPE_TYPES'
import { USER_ROLE_TYPE_SYS_ADMIN, userRoleNameMap } from '../constants/ROLE_TYPES'
import { reportError } from 'lib/useErrorHandler'

const DataContext = createContext({
    company: null,
    companyLogoUrl: null,
    companyId: null,
    companyName: null,
    setCompanyId: (id) => {},
    currentUserData: null, // schema from /api/UserManagement/currentUser
    currentContentLibraryFolderId: null, // currently selected folderId, save the state so it can be returned to when reopening the browse
    isSysAdmin: () => {},
    getPermissions: () => {},
    hasClaim: (fullClaimString, scope, target) => {},
    hasClaimForGlobalScope: (claim) => {},
    hasClaimForCompany: (claim) => {},
    hasClaimForDevelopment: (claim, developmentId) => {},
    hasClaimForPresentation: (claim, presentationId, developmentId) => {},
    getCompanyPermissionLabel: () => {},
    getDevelopmentPermissionLabel: (presentationId, developmentId) => {},
    getPresentationPermissionLabel: () => {},
    getCompanyRole: () => {},
    getDevelopmentRole: (developmentId) => {},
    getPresentationRole: (presentationId, developmentId) => {},
})

const DataProvider = ({ children }) => {
    const [initialised, setInitialised] = useState(false)
    const [companyId, setCompanyId] = useLocalStorage('companyId', null)
    const [companyName, setCompanyName] = useState(null)
    const [companyLogoUrl, setCompanyLogoUrl] = useState(null)
    const [company, setCompany] = useState('company', null)

    // const [currentUserData, setCurrentUserData] = useState(null) //
    const [currentContentLibraryFolderId, setCurrentContentLibraryFolderId] = useState(null) //

    const { user: currentUserData } = useCurrentUser()
    // console.log({ currentUserData })

    async function requestCompanyData() {
        const companyIdToTest = companyId
        if (companyIdToTest != null || !initialised) {
            // check to see if the user has access to this company:  find the company from the list of companies that the user is apart of
            await setInitialised(true)
            const companyObj = currentUserData?.companies?.find((obj) => {
                return obj.id === companyIdToTest
            })
            if (companyObj != null) {
                await initCompanyData(companyObj)
            } else if ((currentUserData && currentUserData?.companies?.length || 0) > 0) {
                // if no company ID is specified in the route, then for now get the first company that the user is apart of
                //await initCompanyData(currentUser.companies[0]);
                await initCompanyData(currentUserData.companies[currentUserData.companies.length - 1]) // get the last one for now as default - landsec
            }
        }
    }

    function initCompanyData(companyObj) {
        setCompany(companyObj)
        setCompanyName(companyObj.name)
        setCompanyId(companyObj.id)
        setCompanyLogoUrl(companyObj.logo)
    }

    useEffect(() => {
        if (!currentUserData) return
        requestCompanyData()
    }, [currentUserData, companyId])

    /// example if a claim is  "Settings.Read"
    // claimPrefix = "Settings" or "Analytics".
    // return the postfix which is the second part of the string after the dot, examples include "Read", "List", "Update"
    function hasClaim(claim, scope, target) {
        return containsClaim(getPermissions(), claim, scope, target) || isSysAdmin()
    }

    function hasClaimForGlobalScope(claim) {
        return containsClaim(getPermissions(), claim, SCOPE_TYPE_GLOBAL, null) || isSysAdmin()
    }

    function hasClaimForCompany(claim) {
        return containsClaim(getPermissions(), claim, SCOPE_TYPE_COMPANY, companyId) || isSysAdmin()
    }

    function hasClaimForDevelopment(claim, developmentId) {
        return containsClaim(getPermissions(), claim, SCOPE_TYPE_DEVELOPMENT, developmentId) || hasClaimForCompany(claim) || isSysAdmin()
    }

    function hasClaimForPresentation(claim, presentationId, developmentId) {
        return containsClaim(getPermissions(), claim, SCOPE_TYPE_PRESENTATION, presentationId) || hasClaimForCompany(claim) || hasClaimForDevelopment(claim, developmentId) || isSysAdmin()
    }

    function getCompanyPermissionLabel() {
        const roleId = getCompanyRole()
        return roleId != null ? userRoleNameMap[roleId] : 'Partial access';
    }

    function getDevelopmentPermissionLabel(developmentId) {
        const roleId = getDevelopmentRole(developmentId)
        return roleId != null ? userRoleNameMap[roleId] : 'Partial access';
    }

    function getPresentationPermissionLabel(presentationId, developmentId) {
        const roleId = getPresentationRole(presentationId, developmentId)
        return roleId != null ? userRoleNameMap[roleId] : 'No presentation role set'
    }

    function getCompanyRole() {
        const permissions = getPermissions()
        if (!Array.isArray(permissions)) return ''
        if (isSysAdmin()) return USER_ROLE_TYPE_SYS_ADMIN
        const permFound = permissions.find((perm) => perm.scope === SCOPE_TYPE_COMPANY && perm.targetId === companyId)
        return permFound ? permFound.roleId : null
    }

    function getDevelopmentRole(developmentId) {
        try {
            developmentId = Number.parseInt(developmentId)
        } catch (e) {
            reportError(e)
        }
        const permissions = getPermissions()
        if (!Array.isArray(permissions)) return ''
        let roleId = getCompanyRole()
        let devPermFound = permissions.find((perm) => perm.scope === SCOPE_TYPE_DEVELOPMENT && perm.targetId === developmentId)
        if (devPermFound && (roleId == null || devPermFound.roleId < roleId)) roleId = devPermFound.roleId
        return roleId
    }

    function getPresentationRole(presentationId, developmentId) {
        try {
            presentationId = Number.parseInt(presentationId)
        } catch (e) {
            reportError(e)
        }
        const permissions = getPermissions()
        if (!Array.isArray(permissions)) return ''
        let roleId = getDevelopmentRole(developmentId)
        let presPermFound = permissions.find((perm) => perm.scope === SCOPE_TYPE_PRESENTATION && perm.targetId === presentationId)
        if (presPermFound && (roleId == null || presPermFound.roleId < roleId)) roleId = presPermFound.roleId
        return roleId
    }

    function isSysAdmin() {
        return currentUserData?.isSysAdmin
    }

    function getPermissions() {
        return currentUserData?.permissions
    }

    return (
        <DataContext.Provider
            value={{
                company,
                companyLogoUrl,
                companyId,
                setCompanyId,
                companyName,
                currentUserData,
                isSysAdmin,
                getPermissions,
                currentContentLibraryFolderId,
                setCurrentContentLibraryFolderId,
                hasClaim,
                hasClaimForCompany,
                hasClaimForDevelopment,
                hasClaimForPresentation,
                hasClaimForGlobalScope,
                getCompanyPermissionLabel,
                getDevelopmentPermissionLabel,
                getPresentationPermissionLabel,
                getCompanyRole,
                getDevelopmentRole,
                getPresentationRole,
            }}>
            {children}
        </DataContext.Provider>
    )
}

export { DataContext, DataProvider }

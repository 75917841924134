import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, TextField, InputLabel, Typography, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useApi from 'lib/api';
import InfoIcon from '@material-ui/icons/Info'
import { useRouter } from 'next/router';
import _ from 'lodash';
import { loginRequest } from 'authConfig'
import { useMsal } from '@azure/msal-react';
import { useErrorHandler } from 'lib/useErrorHandler';
import { LOCAL_STORAGE_AUTH_KEY, MICROSOFT_AUTH, OTP_AUTH, SALESFORCE_AUTH } from 'constants/AUTH_TYPES';

const OTP_ALREADY_SENT_MESSAGE_IDENTIFIER = 'A verification code has already been sent';

const Login = () => {
    const { instance } = useMsal();
    const { handleError } = useErrorHandler();

    const [email, setEmail] = useState('');
    const [emailToCheck, setEmailToCheck] = useState(null);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpSelected, setIsOtpSelected] = useState(false);
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    const { useOtpLogin, useLoginTypes } = useApi();
    const { otpLogin, verifyOtp, loggingIn, isVerifingOtp, isAuthenticated, refreshToken } = useOtpLogin();

    const { loginTypes, loginTypesError, loginTypesLoading } = useLoginTypes(emailToCheck);

    const [otp, setOtp] = useState('');
    const router = useRouter();

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        setIsCheckingAuth(true);
        isAuthenticated().then((result) => {
            if (result?.isAuthenticated) {
                localStorage.setItem('isAuthenticated', "true");
                router.push('/company')
            } else {
                refreshToken().then((refreshTokenResponse) => {
                    if (refreshTokenResponse?.success) {
                        localStorage.setItem('isAuthenticated', "true");
                        router.push('/company');
                    }
                    else {
                        localStorage.setItem('isAuthenticated', "false");
                    }
                });
            }
        }).finally(() => {
            setIsCheckingAuth(false);
        });
    }, [])

    const isEmailValid = (email: string) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    }

    const sendOtp = async (event) => {
        event.preventDefault();

        if (!isEmailValid(email)) {
            enqueueSnackbar('Invalid email', { variant: 'error' });
            return;
        }

        const otpLoginResult = await otpLogin(email);
        if (otpLoginResult?.success) {
            setIsOtpSent(true);
        } else {
            if (tryGetErrorMessage(otpLoginResult?.toString())?.includes?.(OTP_ALREADY_SENT_MESSAGE_IDENTIFIER)) {
                setIsOtpSent(true);
            }
            enqueueSnackbar(tryGetErrorMessage(otpLoginResult?.toString()) || 'Verification code could not be sent', { variant: 'error' });
        }
    }

    const tryGetErrorMessage = (errorString: string) => {
        try {
            console.log(errorString);
            const jsonStartIndex = errorString.indexOf('{');
            const jsonObject = JSON.parse(errorString.substring(jsonStartIndex));
            console.log(jsonObject)
            return jsonObject.message;
        } catch (e) {
            console.error('Error parsing JSON', e);
            return null;
        }
    }

    const validateOtp = async (event) => {
        event.preventDefault();
        if (otp.length !== 12) {
            enqueueSnackbar('Invalid verification code. Please verify the code and try again.', { variant: 'error' });
            return;
        }

        await verifyOtp(email, otp).then((result) => {
            if (result?.success) {
                localStorage.setItem("otpAccessTokenExpireDate", result.tokenExpireDate);
                localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, OTP_AUTH);
                router.push('/company');
            }
        });
    }

    const getLoginTypes = () => {
        if (!isEmailValid(email)) {
            enqueueSnackbar('Invalid email', { variant: 'error' });
            return;
        }

        setEmailToCheck(email);
    }

    const backToEmail = () => {
        setOtp('');
        setIsOtpSent(false);
    }

    const loginWithMicrosoft = () => {
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, MICROSOFT_AUTH);
        instance.loginRedirect(loginRequest).catch((e) => {
            handleError(e)
        })
    }

    const loginWithSalesforce = () => {
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, SALESFORCE_AUTH);
        const baseURl = process.env.NEXT_PUBLIC_SALESFORCE_BASE_URL;
        const clientId = process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ID;
        const redirectUri = process.env.NEXT_PUBLIC_OAUTH2_REDIRECT_URI;
        const salesforceUrl = `${baseURl}/services/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`
        window.location.href = salesforceUrl;
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh" width="100vw" style={{ backgroundColor: '#bfc7d0' }}>

            <Box display="flex" flexDirection="column" width="90vw" maxWidth={'600px'} height="500px" margin="auto" boxShadow="0px 1px 3px 1px #d6d6d6" style={{ backgroundColor: 'white', userSelect: 'none' }} alignItems="center" justifyContent="center">
                <Box style={{ height: '150px', border: '20px solid white', marginTop: '-600px', position: 'absolute' }} >
                    <img src="./logo_prod.png" alt="Logo" />
                </Box>

                <Box display="flex" flexDirection="column" alignItems={isCheckingAuth ? 'center' : 'start'} justifyContent="start" style={{ width: '100%', maxHeight: '80%' }} padding="5vw">
                    {isCheckingAuth && <>
                        <Typography>Checking if user is authenticated...</Typography>
                        <br />
                        <CircularProgress />
                    </>}

                    {!isCheckingAuth && <>
                        {isOtpSent && <Box style={{ display: 'flex', gap: '10px' }} mb={'2vh'} ><InfoIcon /> <Typography>Enter the verification code you received in your email</Typography></Box>}
                        {!loginTypes && <Box width={'100%'}>
                            <InputLabel htmlFor="name">Email</InputLabel>
                            <TextField
                                name="name"
                                id="name"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email..."
                                variant="filled"
                                fullWidth
                                hiddenLabel
                                required
                            />
                        </Box>
                        }
                        {!loginTypes && <Box mt={5} display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
                            <Button type="button" disabled={loggingIn} onClick={getLoginTypes} style={{ width: '100%', height: '40px' }}>
                                <Typography>Login</Typography>
                                {(loggingIn || loginTypesLoading) && <CircularProgress style={{ marginLeft: '10px' }} />}
                            </Button>
                        </Box>
                        }

                        {loginTypes && !isOtpSent &&

                            <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                                <Box mb={2}>
                                    <Typography>Choose how you want to sign-in</Typography>
                                </Box>
                                <Grid container spacing={3}>
                                    {_.uniq(loginTypes).map((loginType: number) => {
                                        if (loginType == 0) {
                                            return (
                                                <Grid item xs={12}>
                                                    <Button
                                                        style={{ width: '100%' }}
                                                        key={loginType}
                                                        type="button"
                                                        onClick={loginWithMicrosoft}>
                                                        Login with your Microsoft account
                                                    </Button>
                                                </Grid>
                                            )
                                        }
                                        if (loginType == 1) {
                                            return (<Grid item xs={12}>

                                                <Button
                                                    style={{ width: '100%' }}
                                                    key={loginType}
                                                    onClick={sendOtp}
                                                >
                                                    Login with OTP (One Time Password)
                                                </Button>
                                            </Grid>)
                                        }
                                        if (loginType == 2) {
                                            return (
                                                <Grid item xs={12}>
                                                    <Button
                                                        style={{ width: '100%' }}
                                                        key={loginType}
                                                        onClick={loginWithSalesforce}
                                                    >
                                                        Login with your Salesforce account
                                                    </Button>
                                                </Grid>

                                            )
                                        }
                                    })}
                                </Grid>
                            </Box>
                        }

                        {isOtpSent &&
                            <Box mt={4} style={{ width: '100%' }}>
                                <InputLabel htmlFor="otp">Enter verification code</InputLabel>
                                <TextField
                                    name="otp"
                                    id="otp"
                                    placeholder="Enter code..."
                                    onChange={(e) => setOtp(e.target.value)}
                                    variant="filled"
                                    fullWidth
                                    hiddenLabel
                                    value={otp}
                                    required
                                />
                            </Box>
                        }

                        {isOtpSent && <Box mt={5} display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Button type="button" onClick={backToEmail} color="secondary" style={{ height: '40px', width: '30%' }}><Typography>Back</Typography></Button>
                            <Button type="button" variant="contained" style={{ height: '40px', width: '30%' }} color="primary" disabled={isVerifingOtp} onClick={validateOtp}>
                                <Typography>Login</Typography>
                                {isVerifingOtp && <CircularProgress style={{ marginLeft: '10px' }} />}
                            </Button>
                        </Box>
                        }
                    </>}
                </Box>
            </Box>
        </Box>
    )
}

export default Login;

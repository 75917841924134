import { appInsightsInstance } from 'lib/useAppInsights'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

export function useErrorHandler() {
    const { enqueueSnackbar } = useSnackbar()

    const handleError = useCallback(
        (err, options = {}) => {
            const { showToast = true, reportError: doReportError = true } = options
            if (doReportError) reportError(err)
            if (showToast)
                enqueueSnackbar(formatErrorMessage(err, options?.msg), { variant: 'error', autoHideDuration: 10000 })
        },
        [enqueueSnackbar]
    )

    return { handleError }
}

export function reportError(err) {
    appInsightsInstance?.trackException({ exception: err })
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(err)
}

function formatErrorMessage(err, msg) {
    return msg || (err instanceof Error ? err.message : err)
}

import useSWR, { mutate } from 'swr'

import url from 'lib/urls'
import { apiAdapter } from 'lib/api/fetch-adapter'
import { useErrorHandler } from 'lib/useErrorHandler'
import { useEffect } from 'react'
import { result } from 'lodash'
import { useRouter } from 'next/router'

const getCurrentUser = async () => await apiAdapter.getCurrentUser()
export default function useCurrentUser() {
    const { handleError } = useErrorHandler()
    const { data, error } = useSWR(`${url.api.GET_USER}`, () => getCurrentUser())
    const router = useRouter()

    useEffect(() => {
        if (data?.error && data?.status == 401 && localStorage.getItem("isAuthenticated")) {
            apiAdapter.refreshToken().then(result => {
                if (!result?.success) {
                    localStorage.setItem('isAuthenticated', 'false');
                    router.push('/login');
                    return;
                }

                mutate(url.api.GET_USER)
            });
        }

        if (!error) return
        handleError(error)
    }, [data, error])

    return { user: data, userError: error, userLoading: !data && !error }
}

export const mutateCurrentUser = async () => mutate(url.api.GET_USER)

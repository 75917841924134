import { useContext, useState, createContext, useMemo } from 'react'
import useSWR, { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useErrorHandler } from 'lib/useErrorHandler'

import FetchAdapter from 'lib/api/fetch-adapter'
import url from 'lib/urls'
import { DataContext } from 'providers/data'
import { mutateCurrentUser } from 'hooks/api/useCurrentUser'
import { parse } from 'papaparse'

const apiAdapter = new FetchAdapter()

const ApiContext = createContext()

// TODO There is no need for any of this to be in context
// Can just export custom hooks

const ApiProvider = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { handleError } = useErrorHandler()
    const { companyId, setCompanyId } = useContext(DataContext)

    const checkCompanyIsCorrect = (companyIdToCheck) => {
        if (companyIdToCheck === undefined || companyIdToCheck === companyId) return
        setCompanyId(companyIdToCheck)
    }

    // Analytics
    const getAnalyticsStatsParamString = (companyId, developmentId, presentationId, startedById, userRoleId, startDate, endDate) => {
        const params = [`companyId=${companyId}`]
        if (developmentId >= 0) params.push(`developmentId=${developmentId}`)
        if (presentationId >= 0) params.push(`presentationId=${presentationId}`)
        if (startedById >= 0) params.push(`startedById=${startedById}`)
        if (userRoleId >= 0) params.push(`userRoleId=${userRoleId}`)
        if (startDate) params.push(`startDate=${startDate}`)
        if (endDate) params.push(`endDate=${endDate}`)
        return params.join('&')
    }
    const getAnalyticsStats = async (paramsString) => await apiAdapter.getAnalyticsStats(paramsString)
    const useAnalyticsStats = (companyId, developmentId, presentationId, startedById, userRoleId, startDate, endDate) => {
        const paramsString = getAnalyticsStatsParamString(companyId, developmentId, presentationId, startedById, userRoleId, startDate, endDate)
        const { data, error } = useSWR(companyId ? `${url.api.ANALYTICS_STATS}?${paramsString}` : null, () => getAnalyticsStats(paramsString))
        return {
            stats: data?.[0],
            statsError: error,
            statsLoading: !data && !error,
        }
    }

    const getAnalyticsContentItemParamString = (companyId, developmentId, presentationId, startedById, userRoleId, startDate, endDate, contentType, orderBy, sortDirection, pageNo, pageSize) => {
        const params = [`companyId=${companyId}`]
        if (developmentId >= 0) params.push(`developmentId=${developmentId}`)
        if (presentationId >= 0) params.push(`presentationId=${presentationId}`)
        if (startedById >= 0) params.push(`startedById=${startedById}`)
        if (userRoleId >= 0) params.push(`userRoleId=${userRoleId}`)
        if (startDate) params.push(`startDate=${startDate}`)
        if (endDate) params.push(`endDate=${endDate}`)
        if (contentType) params.push(`contentType=${contentType}`)
        if (orderBy) params.push(`orderBy=${orderBy}`)
        if (sortDirection >= 0) params.push(`sortDirection=${sortDirection}`)
        if (pageNo >= 0) params.push(`pageNo=${pageNo}`)
        if (pageSize) params.push(`pageSize=${pageSize}`)
        return params.join('&')
    }
    const getAnalyticsContentItems = async (paramsString) => await apiAdapter.getAnalyticsContentItems(paramsString)
    const useAnalyticsContentItems = (companyId, developmentId, presentationId, startedById, userRoleId, startDate, endDate, contentType, orderBy, sortDirection, pageNo, pageSize) => {
        const paramsString = getAnalyticsContentItemParamString(companyId, developmentId, presentationId, startedById, userRoleId, startDate, endDate, contentType, orderBy, sortDirection, pageNo, pageSize)
        const { data, error } = useSWR(companyId ? `${url.api.ANALYTICS_ITEMS}?${paramsString}` : null, () => getAnalyticsContentItems(paramsString))
        return {
            contentItems: data,
            contentItemsError: error,
            contentItemsLoading: !data && !error,
        }
    }

    // Analytics V2
    const useGetAnalytics = (query, analyticsFilter) => {
        const { data, error } = useSWR(analyticsFilter?.companyId ? [`${url.api.ANALYTICS_STATS}/${query}`,
        analyticsFilter.startDate,
        analyticsFilter.endDate,
        analyticsFilter.companyId,
        analyticsFilter.developmentCategoryId,
        analyticsFilter.developmentId,
        analyticsFilter.presentationId,
        analyticsFilter.userId,
        analyticsFilter.userRoleId,
        analyticsFilter.filterWeekends
        ] : null, () => apiAdapter.getAnalytics(query, analyticsFilter), { revalidateOnFocus: false })
        return {
            data: data,
            error: error,
            isLoading: data === undefined && !error, // Newer versions of useSWR have a isLoading property
        }
    }

    // AppPresentations

    // Brushes
    const useBrushes = (scope, targetId) => {
        const { data, error } = useSWR(targetId ? `${url.api.BRUSHES_LIST}/${scope}/${targetId}` : null, () => apiAdapter.getColours(scope, targetId));
        return {
            brushes: data,
            error: error,
            loading: !data && !error,
        }
    }

    // Company
    const mutateCompany = async (companyId) => mutate(`${url.api.COMPANY}/${companyId}`)
    const useCompany = (companyId) => {
        const { data, error } = useSWR(companyId ? `${url.api.COMPANY}/${companyId}` : null, () => apiAdapter.getCompany(companyId))
        return {
            company: data,
            companyError: error,
            companyLoading: !data && !error,
        }
    }

    const useOtpLogin = () => {
        const [loggingIn, setLoggingIn] = useState(false)
        const [isVerifingOtp, setIsVerifingOtp] = useState(false)

        const otpLogin = async (email) => {
            if (loggingIn) {
                return enqueueSnackbar(`Already logging in. Please wait before trying again.`, { variant: 'warning' })
            }

            setLoggingIn(true)

            try {
                const result = await apiAdapter.otpLogin(email);
                enqueueSnackbar(`Verification code sent to ${email}`, {
                    variant: 'success',
                })
                return result;
            } catch (error) {
                return error;
            } finally {
                setLoggingIn(false)
            }
        }

        const verifyOtp = async (email, otp) => {
            setIsVerifingOtp(true)
            try {
                const result = await apiAdapter.verifyOtp(email, otp);
                enqueueSnackbar(`Login successful`, {
                    variant: 'success',
                })
                return result;
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Invalid verification code. Please verify the code and try again.',
                })
            } finally {
                setIsVerifingOtp(false)
            }
        }

        const isAuthenticated = async () => {
            try {
                const result = await apiAdapter.isAuthenticated();
                return result;
            } catch (error) {
                return {
                    isAuthenticated: false
                }
            }
        }

        const refreshToken = async () => {
            try {
                const result = await apiAdapter.refreshToken();
                return result;
            } catch (error) {
                // ignore - do not trigger when user is not auth
            }
        }

        const logout = async () => {
            try {
                await apiAdapter.logout();
                enqueueSnackbar(`Logout successful`, {
                    variant: 'success',
                })
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error logging out',
                })
            }
        }

        return { otpLogin, verifyOtp, logout, loggingIn, isVerifingOtp, refreshToken, isAuthenticated, getLoginTypes }
    }

    const getLoginTypes = async (email) => await apiAdapter.getLoginTypes(email);
    const useLoginTypes = (email) => {
        const { data, error } = useSWR(email ? `${url.api.USERS_GET_LOGIN_TYPE(email)}` : null, () => getLoginTypes(email))
        if (error){
             handleError(error);
        }
  
        return {
            loginTypes: data,
            loginTypesError: error,
            loginTypesLoading: !data && !error,
        }
    }

    const useUpdateCompany = (companyId) => {
        const [updatingCompany, setUpdatingCompany] = useState(false)

        const updateCompany = async (postData) => {
            if (updatingCompany) return enqueueSnackbar(`Already updating company settings. Please wait before trying again.`, { variant: 'warning' })
            setUpdatingCompany(true)

            try {
                const formData = new FormData()
                formData.append('Id', companyId)
                Object.keys(postData).forEach((key) => {
                    if (key === 'MaxImageResolution') {
                        formData.append('MaxImageWidth', postData[key].width)
                        formData.append('MaxImageHeight', postData[key].height)
                    } else if (key === 'MaxVideoResolution') {
                        formData.append('MaxVideoWidth', postData[key].width)
                        formData.append('MaxVideoHeight', postData[key].height)
                    } else {
                        formData.append(key, postData[key])
                    }
                })
                await apiAdapter.updateCompany(formData)
                mutateCompany(companyId)
                mutateCurrentUser()
                enqueueSnackbar(`Settings successfully updated`, {
                    variant: 'success',
                })
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error updating settings',
                })
            } finally {
                setUpdatingCompany(false)
            }
        }

        return { updateCompany, updatingCompany }
    }

    const mutateCompanyDomains = async (companyId) => mutate(`${url.api.COMPANY_DOMAINS}/${companyId}`)
    const getCompanyDomains = async (companyId) => await apiAdapter.getCompanyDomains(companyId)
    const useCompanyDomains = (companyId) => {
        const { data, error } = useSWR(companyId ? `${url.api.COMPANY_DOMAINS}/${companyId}` : null, () => getCompanyDomains(companyId))
        if (error) handleError(error)
        return {
            companyDomains: data,
            companyDomainsError: error,
            companyDomainsLoading: !data && !error,
        }
    }

    const useCompanyDomainMutations = (companyId) => {
        const [creatingCompanyDomain, setCreatingCompanyDomain] = useState(false)
        const [deletingCompanyDomain, setDeletingCompanyDomain] = useState(false)

        const createCompanyDomain = async (domain) => {
            if (creatingCompanyDomain) return enqueueSnackbar(`Already creating domain: ${domain}. Please wait before trying again.`, { variant: 'warning' })
            setCreatingCompanyDomain(true)

            try {
                const body = { companyId, domain }
                const bodyFormatted = JSON.stringify(body)
                await apiAdapter.createCompanyDomain(bodyFormatted)
                mutateCompanyDomains(companyId)
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? `Error creating domain ${domain}`,
                })
            } finally {
                setCreatingCompanyDomain(false)
            }
        }

        const deleteCompanyDomain = async (domainId) => {
            if (deletingCompanyDomain) return enqueueSnackbar(`Already deleting domain. Please wait before trying again.`, { variant: 'warning' })
            setDeletingCompanyDomain(true)

            try {
                await apiAdapter.deleteCompanyDomain(companyId, domainId)
                mutateCompanyDomains(companyId)
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? `Error deleting domain`,
                })
            } finally {
                setDeletingCompanyDomain(false)
            }
        }

        return { createCompanyDomain, deleteCompanyDomain }
    }

    const mutateDevelopmentTypes = async (companyId) => mutate(`${url.api.DEVELOPMENT_TYPES}/${companyId}`)
    const getDevelopmentTypes = async (companyId) => await apiAdapter.getDevelopmentTypes(companyId)
    const useDevelopmentTypes = (companyId) => {
        const { data, error } = useSWR(companyId ? `${url.api.DEVELOPMENT_TYPES}/${companyId}` : null, () => getDevelopmentTypes(companyId))
        if (error) handleError(error)
        return {
            developmentTypes: data,
            developmentTypesError: error,
            developmentTypesLoading: !data && !error,
        }
    }

    const useDevelopmentTypeMutations = (companyId) => {
        const [creatingDevelopmentType, setCreatingDevelopmentType] = useState(false)
        const [updatingDevelopmentType, setUpdatingDevelopmentType] = useState(false)
        const [deletingDevelopmentType, setDeletingDevelopmentType] = useState(false)

        const createDevelopmentType = async (id, name) => {
            if (creatingDevelopmentType) return enqueueSnackbar(`Already creating development type: ${name}. Please wait before trying again.`, { variant: 'warning' })
            setCreatingDevelopmentType(true)

            try {
                const body = { id, companyId, name }
                const bodyFormatted = JSON.stringify(body)
                await apiAdapter.createDevelopmentType(bodyFormatted)
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? `Error creating development type ${name}`,
                })
            } finally {
                setCreatingDevelopmentType(false)
                mutateDevelopmentTypes(companyId)
            }
        }

        const updateDevelopmentType = async (id, name) => {
            if (updatingDevelopmentType) return enqueueSnackbar(`Already updating development type: ${name}. Please wait before trying again.`, { variant: 'warning' })
            setUpdatingDevelopmentType(true)

            try {
                const body = { id, companyId, name }
                const bodyFormatted = JSON.stringify(body)
                await apiAdapter.updateDevelopmentType(bodyFormatted)
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? `Error updating development type ${name}`,
                })
            } finally {
                setUpdatingDevelopmentType(false)
                mutateDevelopmentTypes(companyId)
            }
        }

        const deleteDevelopmentType = async (typeId) => {
            if (deletingDevelopmentType) return enqueueSnackbar(`Already deleting development type. Please wait before trying again.`, { variant: 'warning' })
            setDeletingDevelopmentType(true)

            try {
                await apiAdapter.deleteDevelopmentType(typeId)
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? `Error deleting development type`,
                })
            } finally {
                mutateDevelopmentTypes(companyId)
                setDeletingDevelopmentType(false)
            }
        }

        return {
            createDevelopmentType,
            updateDevelopmentType,
            deleteDevelopmentType,
        }
    }

    // ContentItems
    const getMediaContentItem = async (contentType, assetId) => await apiAdapter.getMediaContentItem(contentType, assetId)
    const useMediaContentItem = (contentType, assetId) => {
        const { data, error } = useSWR(`${url.api.MEDIA_CONTENT_ITEM}/${contentType}/${assetId}`, () => getMediaContentItem(contentType, assetId))

        if (error) handleError(error)
        return {
            mediaContentItem: data,
            mediaContentItemError: error,
            mediaContentItemLoading: !data && !error,
        }
    }

    const saveMediaContentItem = async (body, type) => {
        try {
            const result = await apiAdapter.saveMediaContentItem(body, type)
            if (result)
                enqueueSnackbar(`Content item saved successfully`, {
                    variant: 'success',
                })
            return result
        } catch (err) {
            handleError(err, {
                msg: err.message ?? `Error saving content item`,
            })
        }
    }

    // DataSets
    const mutateDataset = async (datasetId) => mutate(`${url.api.DATASETS}/data/${datasetId}`)
    const mutateDatasetSchema = async (datasetId) => mutate(`${url.api.SCHEMAS}/for/${datasetId}`)
    const getDataset = async (datasetId) => await apiAdapter.getDataset(datasetId)
    const getDatasetSchema = async (datasetId) => await apiAdapter.getDatasetSchema(datasetId)
    const useDataset = (datasetId) => {
        const { data, error } = useSWR(datasetId ? `${url.api.DATASETS}/data/${datasetId}` : null, () => getDataset(datasetId))
        const { data: schema, error: schemaError } = useSWR(datasetId ? `${url.api.SCHEMAS}/for/${datasetId}` : null, () => getDatasetSchema(datasetId))
        const dataset = { ...data, schema }
        const datasetError = schemaError ?? error
        const datasetLoading = (!data && !error) || (!schema && !schemaError)

        // TODO: Setting state in hook is an antipattern since React 16.3. It can cause issues with component rendering - Remove this and move to useEffect
        // See example for useAsset hook
        checkCompanyIsCorrect(dataset?.schema?.companyId)
        return { dataset, datasetError, datasetLoading }
    }

    const getDevelopmentSchemas = async (developmentId) => await apiAdapter.getDevelopmentSchemas(developmentId)
    const useDevelopmentSchemas = (developmentId) => {
        const { data, error } = useSWR(`${url.api.SCHEMAS}/development/${developmentId}`, () => getDevelopmentSchemas(developmentId))
        return {
            developmentSchemas: data,
            developmentSchemasError: error,
            developmentSchemasLoading: !data && !error,
        }
    }

    const mutateDevelopmentDatasets = async (developmentId) => mutate(`${url.api.DATASETS}/${developmentId}`)
    const getDevelopmentDatasets = async (developmentId) => await apiAdapter.getDevelopmentDatasets(developmentId)
    const useDevelopmentDatasets = (developmentId) => {
        const { data, error } = useSWR(`${url.api.DATASETS}/${developmentId}`, () => getDevelopmentDatasets(developmentId))
        return {
            developmentDatasets: data,
            developmentDatasetsError: error,
            developmentDatasetsLoading: !data && !error,
        }
    }

    const useParsedDataSet = async (datasetId) => {
        const dataset = await getDataset(datasetId)
        const parsedData = parse(dataset.data, { skipEmptyLines: true })
        const data = parsedData.data
        return data;
    }

    const saveDataset = async (dataset, developmentId = null) => {
        try {
            await apiAdapter.saveDataset(dataset)
            if (dataset?.id) mutateDataset(developmentId)
            if (developmentId) mutateDevelopmentDatasets(developmentId)
            enqueueSnackbar(`Dataset successfully added`, {
                variant: 'success',
            })
        } catch (error) {
            handleError(error, {
                msg: error.message ?? 'Error adding dataset',
            })
        }
    }

    const useUpdateDataset = () => {
        const [updatingDataset, setUpdatingDataset] = useState({})

        const updateDataset = async (dataset, developmentId = null) => {
            if (updatingDataset[dataset.dataSetId]) return enqueueSnackbar(`Already updating this dataset. Please wait before trying again.`, { variant: 'warning' })
            setUpdatingDataset((prev) => ({
                ...prev,
                [dataset.dataSetId]: true,
            }))

            try {
                const result = await apiAdapter.updateDataset(JSON.stringify(dataset))
                mutateDataset(dataset.dataSetId)
                if (developmentId) mutateDevelopmentDatasets(developmentId)
                enqueueSnackbar(`Dataset successfully updated`, {
                    variant: 'success',
                })
                return result
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error updating dataset',
                })
            } finally {
                setUpdatingDataset((prev) => ({
                    ...prev,
                    [dataset.dataSetId]: false,
                }))
            }
        }

        return { updateDataset, updatingDataset }
    }

    // Woah dude. next level code base upfuckery
    const useUpdateDatasetCSV = () => {
        const [updatingDatasetCSV, setUpdatingDatasetCSV] = useState({})

        const updateDatasetCSV = async (dataset, developmentId = null) => {
            if (updatingDatasetCSV[dataset.dataSetId]) return enqueueSnackbar(`Already updating this dataset. Please wait before trying again.`, { variant: 'warning' })
            setUpdatingDatasetCSV((prev) => ({
                ...prev,
                [dataset.dataSetId]: true,
            }))

            try {
                const result = await apiAdapter.updateDatasetCSV(JSON.stringify(dataset))
                mutateDataset(dataset.dataSetId)
                if (developmentId) mutateDevelopmentDatasets(developmentId)
                enqueueSnackbar(`Dataset successfully updated`, {
                    variant: 'success',
                })
                return result
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error updating dataset',
                })
            } finally {
                setUpdatingDatasetCSV((prev) => ({
                    ...prev,
                    [dataset.dataSetId]: false,
                }))
            }
        }

        return { updateDatasetCSV, updatingDatasetCSV }
    }

    const useDeleteDataset = () => {
        const [deletingDataset, setDeletingDataset] = useState({})

        const deleteDataset = async (id, developmentId) => {
            if (deletingDataset[id]) return enqueueSnackbar(`Already deleting this dataset. Please wait before trying again.`, { variant: 'warning' })
            setDeletingDataset((prev) => ({ ...prev, [id]: true }))

            try {
                const result = await apiAdapter.deleteDataset(id)
                mutateDataset(id)
                if (developmentId) mutateDevelopmentDatasets(developmentId)
                enqueueSnackbar(`Dataset successfully deleted`, {
                    variant: 'success',
                })
                return result;
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error deleting Dataset',
                })
            } finally {
                setDeletingDataset((prev) => ({ ...prev, [id]: false }))
            }
        }

        return { deleteDataset, deletingDataset }
    }

    const updateSchema = async (schema, datasetId) => {
        try {
            await apiAdapter.updateSchema(JSON.stringify({ schemaId: schema.id, schema }))
            if (datasetId) mutateDatasetSchema(datasetId)
            enqueueSnackbar(`Dataset schema successfully updated`, {
                variant: 'success',
            })
        } catch (error) {
            handleError(error, {
                msg: error.message ?? 'Error updating schema',
            })
        }
    }

    // Developments
    const mutateDevelopments = (companyId) => mutate(`${url.api.DEVELOPMENTS}/${companyId}`)
    const getDevelopments = async (companyId) => await apiAdapter.getDevelopments(companyId)
    const useDevelopments = (companyId) => {
        const { data, error } = useSWR(companyId ? `${url.api.DEVELOPMENTS}/${companyId}` : null, () => getDevelopments(companyId))
        if (error) handleError(error)
        return {
            developments: data,
            developmentsError: error,
            developmentsLoading: !data && !error,
        }
    }

    const getDevelopment = async (companyId, developmentId) => await apiAdapter.getDevelopment(companyId, developmentId)
    const useDevelopment = (companyId, developmentId) => {
        const { data, error } = useSWR(companyId && developmentId ? `${url.api.DEVELOPMENTS}/${companyId}/${developmentId}` : null, () => getDevelopment(companyId, developmentId))
        if (error) handleError(error)

        // TODO: Setting state in hook is an antipattern since React 16.3. It can cause issues with component rendering - Remove this and move to useEffect
        // See example for useAsset hook
        checkCompanyIsCorrect(data?.companyId)
        return {
            development: data,
            developmentError: error,
            developmentLoading: !data && !error,
        }
    }

    const getDevelopmentMembers = async (developmentId) => await apiAdapter.getDevelopmentMembers(developmentId)
    const useDevelopmentMembers = (developmentId) => {
        const { data, error } = useSWR(developmentId ? `${url.api.DEVELOPMENT_MEMBERS}/${developmentId}` : null, () => getDevelopmentMembers(developmentId))
        if (error) handleError(error)
        return {
            developmentMembers: data,
            developmentMembersError: error,
            developmentMembersLoading: !data && !error,
        }
    }

    const getDevelopmentThumb = async (developmentId) => await apiAdapter.getDevelopmentThumb(developmentId)
    const useDevelopmentThumb = (developmentId) => {
        const { data, error } = useSWR(developmentId ? `${url.api.DEVELOPMENT_THUMB}/${developmentId}` : null, () => getDevelopmentThumb(developmentId))
        if (error) handleError(error)
        return {
            developmentThumb: data,
            developmentThumbError: error,
            developmentThumbLoading: !data && !error,
        }
    }

    const saveDevelopment = async (formData, isEdit, companyId) => {
        try {
            const result = await apiAdapter.saveDevelopment(formData, isEdit)
            mutateDevelopments(companyId)
            enqueueSnackbar(`Development saved successfully`, {
                variant: 'success',
            })
            return result
        } catch (error) {
            handleError(error, {
                msg: error.message ?? 'Error saving development',
            })
        }
    }

    // Files
    const mutateFileInfo = assetId => mutate(`${url.api.FILE_INFO_BY_ASSET_ID}/${assetId}`)
    const getAsset = async (assetId) => await apiAdapter.getFileInfo(assetId)
    const useAsset = (assetId, shouldFetch = true, options = {}) => {
        const { data, error } = useSWR(assetId && shouldFetch ? `${url.api.FILE_INFO_BY_ASSET_ID}/${assetId}` : null, () => getAsset(assetId), options)
        if (error) handleError(error)
        return {
            asset: data,
            assetError: error,
            assetLoading: !data && !error,
        }
    }

    const getAssetUrl = async (asset) => await apiAdapter.downloadFile('relatedFileId' in asset ? asset.relatedFileId : asset.id)
    const useAssetUrl = (asset, shouldFetch = true, options = {}) => {
        const { data, error } = useSWR(asset?.id && shouldFetch ? `${url.api.DOWNLOAD_FILE}/${asset.id}` : null, () => getAssetUrl(asset), options)
        if (error) handleError(error)
        const assetUrl = useMemo(() => {
            try {
                const url = data && URL.createObjectURL(data)
                return url
            } catch (error) {
                handleError(error)
                return
            }
        }, [data])
        return { url: assetUrl, urlError: error, urlLoading: !data && !error }
    }

    const useUpdateLockStatus = (fileInfo) => {
        const updateLockStatus = async (lockStatus, sessionId = 0) => {
            try {
                await apiAdapter.lockFile(fileInfo.id, lockStatus, sessionId);

                if (fileInfo.isLocked != lockStatus)
                    enqueueSnackbar(lockStatus ? 'Item locked successfully' : 'Item unlocked successfully', { variant: 'success' });

                mutateFileInfo(fileInfo.assetId);
            } catch (error) {
                mutateFileInfo(fileInfo.assetId);
                handleError(error, {
                    msg: error.message ?? 'Error updating lock  status',
                })
            }
        }

        return { updateLockStatus };
    }

    // Fonts

    // GeneralCms
    const getRoles = async () => await apiAdapter.getRoles()
    const useRoles = () => {
        const { data, error } = useSWR(`${url.api.ROLES}`, () => getRoles())
        return {
            roles: data,
            rolesError: error,
            rolesLoading: !data && !error,
        }
    }

    const getContentTypes = async () => await apiAdapter.getContentTypes()
    const useContentTypes = () => {
        const { data, error } = useSWR(`${url.api.CONTENT_TYPES}`, () => getContentTypes())
        if (error) handleError(error)
        return {
            contentTypes: data,
            contentTypesError: error,
            contentTypesLoading: !data && !error,
        }
    }

    const getColumnTypes = async () => await apiAdapter.getColumnTypes()
    const useColumnTypes = () => {
        const { data, error } = useSWR(`${url.api.COLUMN_TYPES}`, () => getColumnTypes())
        if (error) handleError(error)
        // TODO: This is a temp fix until enumerations are implemented
        let columnTypes = data?.filter(({ id }) => id !== 6)
        return {
            columnTypes,
            columnTypesError: error,
            columnTypesLoading: !data && !error,
        }
    }

    // Health

    // Icons

    // IDD
    const useUpdateIDDContentItem = (metaFileId, assetId) => {
        const [updatingIDDContentItem, setUpdatingIDDContentItem] = useState(false)

        const updateIDDContentItem = async (canvasData, sessionId) => {
            if (updatingIDDContentItem) return enqueueSnackbar(`Already updating this canvas. Please wait before trying again.`, { variant: 'warning' })
            setUpdatingIDDContentItem(true)

            try {
                const body = {
                    metaFileId,
                    contentType: canvasData.contentType,
                    contract: canvasData,
                    currentSessionId: sessionId
                }
                const bodyFormatted = JSON.stringify(body)
                await apiAdapter.saveIDDContentItem(bodyFormatted)
                mutateFileInfo(assetId);
                enqueueSnackbar(`Canvas successfully updated`, {
                    variant: 'success',
                })
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error updating canvas',
                })
            } finally {
                setUpdatingIDDContentItem(false)
            }
        }

        return { updateIDDContentItem, updatingIDDContentItem }
    }

    // Presentations
    const mutatePresentations = (developmentId) => mutate(`${url.api.PRESENTATIONS}/${developmentId}`)
    const getPresentations = async (developmentId) => await apiAdapter.getPresentations(developmentId)
    const usePresentations = (developmentId) => {
        const { data, error } = useSWR(`${url.api.PRESENTATIONS}/${developmentId}`, () => getPresentations(developmentId))
        if (error) handleError(error)
        return {
            presentations: data,
            presentationsError: error,
            presentationsLoading: !data && !error,
        }
    }

    const getPresentation = async (developmentId, presentationId, detailLevel) => await apiAdapter.getPresentation(developmentId, presentationId, detailLevel)
    const usePresentation = (developmentId, presentationId, detailLevel) => {
        const { data, error } = useSWR(`${url.api.PRESENTATIONS}/${developmentId}/${presentationId}?detailLevel=${detailLevel}`, () => getPresentation(developmentId, presentationId, detailLevel))
        if (error) handleError(error)
        return {
            presentation: data,
            presentationError: error,
            presentationLoading: !data && !error,
        }
    }

    const getPresentationById = async (presentationId, detailLevel) => await apiAdapter.getPresentationById(presentationId, detailLevel)
    const usePresentationById = (presentationId, detailLevel = 'full') => {
        const { data, error } = useSWR(presentationId ? `${url.api.PRESENTATIONS_ID}/${presentationId}?detailLevel=${detailLevel}` : null, () => getPresentationById(presentationId, detailLevel))
        if (error) handleError(error)
        return {
            presentation: data,
            presentationError: error,
            presentationLoading: !data && !error,
        }
    }

    const mutateArchivedPresentations = (developmentId) => mutate(`${url.api.PRESENTATIONS}/archived/${developmentId}`)
    const getArchivedPresentations = async (developmentId) => await apiAdapter.getArchivedPresentations(developmentId)
    const useArchivedPresentations = (developmentId) => {
        const { data, error } = useSWR(`${url.api.PRESENTATIONS}/archived/${developmentId}`, () => getArchivedPresentations(developmentId))
        if (error) handleError(error)
        return {
            archivedPresentations: data,
            archivedPresentationsError: error,
            archivedPresentationsLoading: !data && !error,
        }
    }

    const getPresentationThumb = async (presentationId) => await apiAdapter.getPresentationThumb(presentationId)
    const usePresentationThumb = (presentationId) => {
        const { data, error } = useSWR(presentationId ? `${url.api.PRESENTATION_THUMB}/${presentationId}` : null, () => getPresentationThumb(presentationId))
        if (error) handleError(error)
        return {
            presentationThumb: data,
            presentationThumbError: error,
            presentationThumbLoading: !data && !error,
        }
    }

    const savePresentation = async (presentation, isEdit, developmentId) => {
        try {
            const result = await apiAdapter.savePresentation(presentation, isEdit)
            mutatePresentations(developmentId);
            enqueueSnackbar(`Presentation saved successfully`, {
                variant: 'success',
            })
            return result
        } catch (error) {
            handleError(error, error.message ?? 'Error saving presentation')
        }
    }

    const archiveDevelopment = async (development) => {
        try {
            await apiAdapter.archiveDevelopment(development.id);
            if (development.companyId)
                mutateDevelopments(development.companyId);

            enqueueSnackbar(`Development successfully archived`, {
                variant: 'success',
            })
        } catch (error) {
            handleError(error, {
                msg: error.message ?? 'Error archiving development',
            })
        }
    }

    const unArchiveDevelopment = async (development) => {
        try {
            await apiAdapter.unArchiveDevelopment(development.id);
            if (development.companyId)
                mutateDevelopments(development.companyId);

            enqueueSnackbar(`Development successfully restored`, {
                variant: 'success',
            })
        } catch (error) {
            handleError(error, {
                msg: error.message ?? 'Error restoring development',
            })
        }
    }


    const archivePresentation = async (presentation) => {
        try {
            await apiAdapter.archivePresentation(presentation.id).catch((error) => {
                handleError(error, {
                    msg: error.message ?? 'Error archiving presentation',
                })
            })
            if (presentation.developmentId)
                mutatePresentations(presentation.developmentId);

            enqueueSnackbar(`Presentation successfully archived`, {
                variant: 'success',
            })
        } catch (error) {
            handleError(error, {
                msg: error.message ?? 'Error archiving presentation',
            })
        }
    }

    const restorePresentation = async (presentation) => {
        try {
            await apiAdapter.restorePresentation(presentation.id)
            if (presentation.developmentId) mutateArchivedPresentations(presentation.developmentId)
        } catch (error) {
            handleError(error)
        }
    }

    const getPresentationDuplicationStatus = async (presentationId) => {
        try {
            const response = await apiAdapter.getPresentationDuplicationStatus(presentationId)
            return response
        } catch (error) {
            handleError(error)
        }
    }

    const duplicatePresentation = async (presentation) => {
        try {
            const response = await apiAdapter.duplicatePresentation(presentation.id);
            if (response.error)
                handleError(response, {
                    msg: response.message
                });
            if (presentation.developmentId) mutatePresentations(presentation.developmentId)
        } catch (error) {
            handleError(error)
        }
    }

    const abortDuplication = async (presentation) => {
        try {
            await apiAdapter.abortDuplication(presentation.id)
            if (presentation.developmentId) mutatePresentations(presentation.developmentId)
        } catch (error) {
            handleError(error)
        }
    }

    // ReportingEgest

    // Settings
    const mutateSettings = (scope, targetId) => mutate(`${url.api.SETTINGS}/${scope}${targetId ? `/${targetId}` : ''}`)
    const getSettings = async (scope, targetId) => await apiAdapter.getSettings(scope, targetId)
    const useSettings = (scope, targetId) => {
        const { data, error } = useSWR(scope ? `${url.api.SETTINGS}/${scope}${targetId ? `/${targetId}` : ''}` : null, () => getSettings(scope, targetId))
        if (error) handleError(error)
        return {
            settings: data,
            settingsError: error,
            settingsLoading: !data && !error,
        }
    }

    const useUpdateSettings = (scope, targetId) => {
        const [updatingSettings, setUpdatingSettings] = useState(false)

        const updateSettings = async (postData) => {
            if (updatingSettings) return enqueueSnackbar(`Already updating these settings. Please wait before trying again.`, { variant: 'warning' })
            setUpdatingSettings(true)

            try {
                const body = {
                    data: postData,
                    scope: parseInt(scope),
                    targetId: parseInt(targetId),
                }
                const bodyFormatted = JSON.stringify(body)
                await apiAdapter.saveSettings(bodyFormatted)
                mutateSettings(scope, targetId)
                enqueueSnackbar(`Settings successfully updated`, {
                    variant: 'success',
                })
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error updating settings',
                })
            } finally {
                setUpdatingSettings(false)
            }
        }

        return { updateSettings, updatingSettings }
    }

    const useDeleteSettings = (scope, targetId) => {
        const [deletingSettings, setDeletingSettings] = useState(false)

        const deleteSettings = async (deleteData) => {
            if (deletingSettings) return enqueueSnackbar(`Already deleting this setting. Please wait before trying again.`, { variant: 'warning' })
            setDeletingSettings(true)

            try {
                for (const settingKey in deleteData) {
                    await apiAdapter
                        .deleteSetting(scope, targetId, settingKey)
                        .then(() => enqueueSnackbar(`Deleted ${settingKey} successfully`, { variant: 'success' }))
                        .catch((error) => {
                            handleError(error)
                        })
                }
                mutateSettings(scope, targetId)
            } finally {
                setDeletingSettings(false)
            }
        }

        return { deleteSettings, deletingSettings }
    }

    // const saveSettings = async (settings, scope, targetId) => {
    //     try {
    //         const result = await apiAdapter.saveSettings(settings)
    //         if (presentation.developmentId) mutatePresentations(presentation.developmentId)
    //         return result
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // saveSettings(body) {
    //     const endpoint = url.api.BASE + url.api.SAVE_SETTINGS
    //     return this._fetchPut(endpoint, body, 'json', null)
    // }

    // Share

    // User
    const mutateCompanyUsers = async (companyId, query) => mutate(`${url.api.USERS_COMPANY}/${companyId}`)
    const getCompanyUsers = async (companyId, query) => await apiAdapter.getCompanyUsers(companyId, query)
    const useCompanyUsers = (companyId, query) => {
        const { data, error } = useSWR(`${url.api.USERS_COMPANY}/${companyId}`, () => getCompanyUsers(companyId))
        if (error) handleError(error)

        const users = useMemo(() => {
            if (!data) return []
            return data.filter(({ email, firstName, lastName, displayName, highestPrivilege }) => {
                if (!query) return true
                if (email?.toLowerCase().includes(query.toLowerCase())) return true
                if (firstName?.toLowerCase().includes(query.toLowerCase())) return true
                if (lastName?.toLowerCase().includes(query.toLowerCase())) return true
                if (displayName?.toLowerCase().includes(query.toLowerCase())) return true
                if (highestPrivilege?.name?.toLowerCase().includes(query.toLowerCase())) return true
                return false
            })
        }, [data, query])

        return {
            users,
            usersError: error,
            usersLoading: !data && !error,
        }
    }

    const getUserById = async (userId, detailLevel = 'Light') => await apiAdapter.getUserById(userId, detailLevel)
    const useUser = (userId, detailLevel = 'Light') => {
        const { data, error } = useSWR(userId ? `${url.api.USER_MANAGEMENT}/${userId}/?detailLevel=${detailLevel}` : null, () => getUserById(userId, detailLevel))
        if (error) handleError(error)
        return { user: data, userError: error, userLoading: !data && !error }
    }


    const mutateAllUserPreferences = async () => mutate(`${url.api.GET_ALL_USER_PREFRENCES}`)
    const getAllUserPreferences = async () => await apiAdapter.getAllUserPreferences();
    const useAllUserPreferences = () => {
        const { data, error } = useSWR(`${url.api.GET_ALL_USER_PREFRENCES}`, () => getAllUserPreferences())
        if (error) handleError(error)
        return { userPreferences: data, userPreferencesError: error, userPreferencesLoading: !data && !error }
    }

    const mutateSingleUserPreferences = async (key) => mutate(`${url.api.GET_SINGLE_USER_PREFRENCES}/${key}`)
    const getSingleUserPreferences = async (key) => await apiAdapter.getSingleUserPreferences(key);
    const useSingleUserPreferences = (key) => {
        const { data, error } = useSWR(`${url.api.GET_SINGLE_USER_PREFRENCES}/${key}`, () => getSingleUserPreferences(key))
        if (error) handleError(error)
        return { userPreferences: data, userPreferencesError: error, userPreferencesLoading: !data && !error }
    }

    const useUpdateUserPreferences = () => {
        const [loading, setLoading] = useState(false); // It should be a better way

        const updatePermissions = async (key, value) => {
            try {
                setLoading(true);
                const body = JSON.stringify(value);
                const result = await apiAdapter.updateUserPreference(key, JSON.stringify(body))

                enqueueSnackbar(`Configuration successfully updated`, {
                    variant: 'success',
                })
                mutateSingleUserPreferences(key);
                mutateAllUserPreferences();
                return result;
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error updating configuration',
                })
            } finally {
                setLoading(false);
            }
        }

        return { updatePermissions, loading }
    }

    const mutatePermissions = async (scope, targetId) => mutate(`${url.api.USERS_PERMISSIONS}/${scope}/${targetId}`)
    const getPermissions = async (scope, targetId) => await apiAdapter.getPermissions(scope, targetId)
    const usePermissions = (scope, targetId) => {
        const { data, error } = useSWR(scope >= 0 && targetId >= 0 ? `${url.api.USERS_PERMISSIONS}/${scope}/${targetId}` : null, () => getPermissions(scope, targetId))
        if (error) handleError(error)
        return {
            permissions: data,
            permissionsError: error,
            permissionsLoading: !data && !error,
        }
    }

    const getPermissionsList = async (userId, companyId) => await apiAdapter.getPermissionsList(userId, companyId)
    const usePermissionsList = (userId, companyId) => {
        const { data, error } = useSWR(userId !== undefined && companyId !== undefined ? `${url.api.USERS_PERMISSIONS_LIST}/${userId}/${companyId}` : null, () => getPermissionsList(userId, companyId))
        if (error) handleError(error)
        return {
            permissions: data,
            permissionsError: error,
            permissionsLoading: !data && !error,
        }
    }

    const useUpdatePermissions = () => {
        const updatePermissions = async ({ userId, companyId, permissions }) => {
            try {
                const body = { userId, companyId, permissions }
                const result = await apiAdapter.updatePermissions(JSON.stringify(body))

                enqueueSnackbar(`Permission successfully updated`, {
                    variant: 'success',
                })
                return result
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error updating permission',
                })
            }
        }

        return { updatePermissions }
    }

    const useDeletePermission = () => {
        const [deletingPermission, setDeletingPermission] = useState({})

        const getDeletePermissionKey = (id) => `${id}`

        const deletePermission = async (id, scope, targetId) => {
            const permissionKey = getDeletePermissionKey(id)
            if (deletingPermission[permissionKey]) return enqueueSnackbar(`Already deleting this permission. Please wait before trying again.`, { variant: 'warning' })

            setDeletingPermission((prev) => ({
                ...prev,
                [permissionKey]: true,
            }))

            try {
                const result = await apiAdapter.deletePermission(id)
                mutatePermissions(scope, targetId)
                enqueueSnackbar(`Permission successfully deleted`, {
                    variant: 'success',
                })
                return result
            } catch (error) {
                handleError(error, {
                    msg: error.message ?? 'Error deleting permission',
                })
            } finally {
                setDeletingPermission((prev) => ({
                    ...prev,
                    [permissionKey]: false,
                }))
            }
        }

        return { deletePermission, deletingPermission, getDeletePermissionKey }
    }

    // For some reason error is a string of status code + error message
    // Since we don't know where error *should* have this format
    // (if any, I can't think of a reason why would you want this?
    // since it's poor for for debugging and poor for user facing errors)
    // we have to extract the status code and error message
    const extractStatusCodeAndMessage = (error) => error.message.match(/(^\d{3}) (.+)/)
    const useInviteUser = (context) => {
        const [invitingUser, setInvitingUser] = useState({})

        const inviteUser = async (email, companyId, permissions) => {
            if (invitingUser[email]) return enqueueSnackbar(`Already ${context === 'add' ? 'adding' : 'inviting'} ${email}. Please wait before trying again.`, { variant: 'warning' })

            setInvitingUser((prev) => ({ ...prev, [email]: true }))
            const body = { invitationEmail: email, companyId }
            if (permissions?.length) body.permissions = permissions
            try {
                const result = await apiAdapter.inviteUser(JSON.stringify(body))
                mutateCompanyUsers(companyId)
                permissions?.forEach(({ scope, targetId }) => mutatePermissions(scope, targetId))
                const success = context === 'add' ? `${email} successfully added` : `Invitation sent successfully to ${email}`
                enqueueSnackbar(success, { variant: 'success' })
                return result
            } catch (error) {
                const [, statusCode, msg] = extractStatusCodeAndMessage(error)
                // Note: server resturns a 400 when we are not allowed to add a certain email address
                // ideally this should be a 403 or 422
                handleError(error, { msg, reportError: statusCode !== 400 })
            } finally {
                setInvitingUser((prev) => ({ ...prev, [email]: false }))
            }
        }

        const reinviteUser = async (userId, companyId, email) => {
            if (invitingUser[email]) return enqueueSnackbar(`Already ${context === 'add' ? 'adding' : 'inviting'} ${email}. Please wait before trying again.`, { variant: 'warning' })

            setInvitingUser((prev) => ({ ...prev, [email]: true }))
            const body = { userId, companyId }
            try {
                const result = await apiAdapter.reinviteUser(JSON.stringify(body))
                mutateCompanyUsers(companyId)
                const success = context === 'add' ? `${email} successfully added` : `Invitation sent successfully to ${email}`
                enqueueSnackbar(success, { variant: 'success' })
                return result
            } catch (error) {
                const [, statusCode, msg] = extractStatusCodeAndMessage(error)
                // Note: server resturns a 400 when we are not allowed to add a certain email address
                // ideally this should be a 403 or 422
                handleError(error, { msg, reportError: statusCode !== 400 })
            } finally {
                setInvitingUser((prev) => ({ ...prev, [email]: false }))
            }
        }

        return { inviteUser, invitingUser, reinviteUser }

        // reinvite user
        // move resend button below and
        // show expires in XX days after pending
    }

    const deleteUserFromCompany = async (id, companyId) => {
        try {
            const result = await apiAdapter.deleteUserFromCompany(id, companyId)
            mutateCompanyUsers(companyId)
            enqueueSnackbar('User deleted successfully', {
                variant: 'success',
            })
            return result
        } catch (error) {
            handleError(error, { msg: error.message ?? 'Error deleting user' })
        }
    }

    const availableMethods = {
        checkCompanyIsCorrect,

        // Analytics
        useAnalyticsStats,
        useAnalyticsContentItems,
        getAnalyticsStatsParamString,

        useGetAnalytics,

        // ContentItems
        useMediaContentItem,
        saveMediaContentItem,

        // Brushes
        useBrushes,

        useOtpLogin,

        // Company
        useCompany,
        useUpdateCompany,
        useLoginTypes,
        useCompanyDomains,
        useCompanyDomainMutations,
        useDevelopmentTypes,
        useDevelopmentTypeMutations,

        // Datasets
        useDataset,
        useDevelopmentSchemas,
        useDevelopmentDatasets,
        useParsedDataSet,
        saveDataset,
        useUpdateDataset,
        useUpdateDatasetCSV,
        useDeleteDataset,
        updateSchema,

        // Developments
        useDevelopments,
        useDevelopment,
        useDevelopmentMembers,
        useDevelopmentThumb,
        saveDevelopment,
        unArchiveDevelopment,
        archiveDevelopment,

        // Files
        useAsset,
        useAssetUrl,
        useUpdateLockStatus,

        // GeneralCms
        useRoles,
        useContentTypes,
        useColumnTypes,

        // IDD
        useUpdateIDDContentItem,

        // Presentations
        usePresentations,
        usePresentation,
        usePresentationById,
        useArchivedPresentations,
        usePresentationThumb,
        savePresentation,
        archivePresentation,
        restorePresentation,
        getPresentationDuplicationStatus,
        duplicatePresentation,
        abortDuplication,

        // Settings
        useSettings,
        useUpdateSettings,
        useDeleteSettings,

        // User
        useCompanyUsers,
        useUser,
        useSingleUserPreferences,
        useAllUserPreferences,
        useUpdateUserPreferences,
        useInviteUser,
        deleteUserFromCompany,
        usePermissions,
        usePermissionsList,
        useUpdatePermissions,
        useDeletePermission,
    }

    return <ApiContext.Provider value={availableMethods}>{children}</ApiContext.Provider>
}

export { ApiProvider }

const useApi = () => {
    const context = useContext(ApiContext)

    if (context === undefined) {
        throw new Error('useApi must be used within ApiProvider')
    }

    return context
}

export default useApi

import { NavigationClient } from '@azure/msal-browser'

// Config object to be passed to Msal on creation

console.log('authConfig.js  clientId', process.env.NEXT_PUBLIC_MSAL_CLIENT_ID)
console.log('authConfig.js  authority', process.env.NEXT_PUBLIC_MSAL_AUTHORITY)
console.log('authConfig.js  redirectUri', process.env.NEXT_PUBLIC_MSAL_REDIRECT_URL)
console.log('authConfig.js knownAuthorities', process.env.NEXT_PUBLIC_MSAL_KNOWN_AUTHORITIES)

export const msalConfig = {
    auth: {
        clientId: process.env.NEXT_PUBLIC_MSAL_CLIENT_ID,
        authority: process.env.NEXT_PUBLIC_MSAL_AUTHORITY,
        redirectUri: process.env.NEXT_PUBLIC_MSAL_REDIRECT_URL, //process.env.NEXT_PUBLIC_MSAL_REDIRECT_URL,
        navigateToLoginRequestUrl: true,
        knownAuthorities: [process.env.NEXT_PUBLIC_MSAL_KNOWN_AUTHORITIES],
        // postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
        storeAuthStateInCookie: true, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                //console.log(message)
            },
            piiLoggingEnabled: false,
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
    },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [process.env.NEXT_PUBLIC_MSAL_SCOPES],
}

const tokenRequest = {
    scopes: [process.env.NEXT_PUBLIC_MSAL_SCOPES], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
}

export class CustomNavigationClient extends NavigationClient {
    constructor(router) {
        super()
        this.router = router
    }

    /**
     * Navigates to other pages within the same web application
     * You can use the useRouter hook provided by next.js to take advantage of client-side routing
     * @param url
     * @param options
     */
    async navigateInternal(url, options) {
        const relativePath = url.replace(window.location.origin, '')
        localStorage.setItem('msal-next-url', relativePath)

        if (options.noHistory) {
            this.router.replace(relativePath)
        } else {
            this.router.push(relativePath)
        }

        return false
    }
}

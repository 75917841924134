export const LOCAL_STORAGE_AUTH_KEY = "authMethod";
export const OTP_EXPIRATION_KEY = "otpAccessTokenExpireDate";

export const MICROSOFT_AUTH = "microsoft";
export const SALESFORCE_AUTH = "salesforce";
export const OTP_AUTH = "otp";

export const authTypesMap = {
  [MICROSOFT_AUTH] : MICROSOFT_AUTH,
  [SALESFORCE_AUTH] : SALESFORCE_AUTH,
  [OTP_AUTH] : OTP_AUTH
}

export const isMicrosoftAuth = () => localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) === MICROSOFT_AUTH;
export const isSalesforceAuth = () => localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) === SALESFORCE_AUTH;
export const isOtpAuth = () => localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) === OTP_AUTH;
export const hasAuthMethod = () => !!localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

export const isLoginPage = () => window.location.href.includes?.('login');
export const isOAuthPage = () => window.location.href.includes?.('oauth');